<template>
  <form id="withdraw" @submit.prevent="onSubmit">
    <div class="withdrawPage">
      <div id="section-content" class="container container-fluid">
        <div class="bar-back">
          <router-link to="/member/main"><i class="fas fa-chevron-left"></i> หน้าหลัก</router-link>
        </div>
        <div
          class="p-2 w-100 bg-light main-content align-self-stretch"
          style="min-height: calc((100vh - 176px) - 50px)"
        >
          <div
            class="bgwhitealpha text-secondary shadow-sm rounded p-2 px-2 xtarget col-lotto d-flex flex-row mb-1 pb-0"
          >
            <div class="lotto-title">
              <h4><i class="fas fa-vote-yea"></i> แจ้งถอนเงิน</h4>
            </div>
          </div>
          <div class="bgwhitealpha text-secondary shadow-sm rounded p-2 xtarget col-lotto">
            <div class="form-row" style="display: flex; justify-content: center; align-items: center">
              <div class="col-12 col-sm-12 col-md-3 col-lg-12 text-center" style="width: auto; max-width: 200px">
                <input type="radio" name="bank2" class="input-hidden" checked="" /><label
                  for="kbank-1"
                  class="bank-radio"
                  ><img
                    :src="imglink(myBankInfo.listBankAccount.bankName)"
                    class="icon-bank bnk48_004"
                    :style="'background: ' + myBankInfo.listBankAccount.bankColor"
                  /><br /><span>{{ myBankInfo.listBankAccount.bankName }}</span
                  ><span class="badge badge-dark">{{ myBankInfo.listBankAccount.bankNo }}</span></label
                >
              </div>
              <div class="border-bottom w-100 my-2"></div>
              <div class="col-12 col-sm-12 col-md-6">
                <label><i class="fas fa-coins"></i> จำนวนเงินที่ถอนได้</label><br />
                <div class="alert alert-success py-2">
                  <h3 class="thb text-success text-center my-0 w-100" data-id="credit_balance">{{ getBalance }}</h3>
                </div>
              </div>
              <div class="col-12 col-sm-12 col-md-6">
                <label><i class="fas fa-hand-holding-usd"></i> จำนวนเงินที่ต้องการถอน</label><br />
                <div class="input-group">
                  <div class="input-group-prepend">
                    <span class="input-group-text">฿</span>
                  </div>
                  <input
                    type="tel"
                    class="form-control form-control-lg money-withdraw"
                    placeholder="ระบุเฉพาะตัวเลข"
                    v-model.trim="$v.amount.$model"
                    required
                  />
                  <div class="input-group-append">
                    <button class="btn btn-warning" type="button" @click="setAmount">ทั้งหมด</button>
                  </div>
                </div>
                <small class="text-secondary">ถอนเงินขั้นต่ำ {{ getMinWithdraw }}</small>
              </div>
              <div class="col-12 col-sm-12 col-md-12">
                <label class="mt-2"><i class="far fa-star"></i> หมายเหตุ</label>
                <textarea
                  name="note"
                  cols="30"
                  rows="2"
                  class="form-control"
                  v-model="note"
                  style="resize: none"
                ></textarea>
              </div>
            </div>
          </div>
          <div class="bg-white p-2 rounded shadow-sm w-100 mb-5">
            <div class="row">
              <div class="col pr-1">
                <router-link to="/member/main">
                  <button class="btn btn-secondary btn-block">ยกเลิก</button>
                </router-link>
              </div>
              <div class="col pl-1">
                <button class="btn btn-success btn-block" type="submit">ถอนเงิน</button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </form>
</template>

<script>
import { get, checkNumeric } from '@/utils/'
import { required } from 'vuelidate/lib/validators'
import { mapState, mapGetters, mapActions } from 'vuex'
import * as numeral from 'numeral'
import { makeTransferService } from '@/services'
import { STATUS_SUCCESS } from '@/constants'
import baac from '@/assets/banks/baac.svg'
import bay from '@/assets/banks/bay.svg'
import bbl from '@/assets/banks/bbl.svg'
import bnp from '@/assets/banks/bnp.svg'
import boa from '@/assets/banks/boa.svg'
import cacib from '@/assets/banks/cacib.svg'
import cimb from '@/assets/banks/cimb.svg'
import citi from '@/assets/banks/citi.svg'
import db from '@/assets/banks/db.svg'
import ghb from '@/assets/banks/ghb.svg'
import gsb from '@/assets/banks/gsb.svg'
import hsbc from '@/assets/banks/hsbc.svg'
import ibank from '@/assets/banks/ibank.svg'
import icbc from '@/assets/banks/icbc.svg'
import jpm from '@/assets/banks/jpm.svg'
import kbank from '@/assets/banks/kbank.svg'
import kkp from '@/assets/banks/kkp.svg'
import ktb from '@/assets/banks/ktb.svg'
import lhb from '@/assets/banks/lhb.svg'
import mb from '@/assets/banks/mb.svg'
import mega from '@/assets/banks/mega.svg'
import mufg from '@/assets/banks/mufg.svg'
import rbs from '@/assets/banks/rbs.svg'
import sc from '@/assets/banks/sc.svg'
import scb from '@/assets/banks/scb.svg'
import smbc from '@/assets/banks/smbc.svg'
// import tcap from '@/assets/banks/tcap.svg'
import tisco from '@/assets/banks/tisco.svg'
import tmb from '@/assets/banks/tmb.svg'
import uob from '@/assets/banks/uob.svg'
import ttb from '@/assets/banks/ttb.svg'
export default {
  data () {
    return {
      amount: null,
      note: '',
      imageBankMapping: {
        baac: baac,
        bay: bay,
        bbl: bbl,
        bnp: bnp,
        boa: boa,
        cacib: cacib,
        cimb: cimb,
        citi: citi,
        db: db,
        ghb: ghb,
        gsb: gsb,
        hsbc: hsbc,
        ibank: ibank,
        icbc: icbc,
        jpm: jpm,
        kbank: kbank,
        kkp: kkp,
        ktb: ktb,
        lhb: lhb,
        mb: mb,
        mega: mega,
        mufg: mufg,
        rbs: rbs,
        sc: sc,
        scb: scb,
        smbc: smbc,
        // tcap: tcap,
        tisco: tisco,
        tmb: tmb,
        uob: uob,
        ttb: ttb
      }
    }
  },
  async mounted () {
    await this.setLoading(true)
    await this.getMyAccountBanks()
    await this.setLoading(false)
  },
  computed: {
    ...mapGetters(['getBalance', 'getMinWithdraw']),
    ...mapState({
      myBankInfo: state => state.bankModule.myBankInfo
    })
  },
  watch: {
    amount: function (value) {
      if (!checkNumeric(value)) {
        this.amount = value.substring(0, value.length - 1)
      } else if (value.length === 1 && (value === '0' || value === '.')) {
        this.amount = ''
      }
    }
  },
  methods: {
    ...mapActions(['getMyAccountBanks', 'setLoading', 'openInfoPopup', 'setCreditTurn']),
    setAmount () {
      this.amount = numeral(this.getBalance)._value
    },
    async onSubmit () {
      await this.setLoading(true)
      try {
        const resp = await makeTransferService(this.amount, this.note)
        if (resp.status_code === STATUS_SUCCESS) {
          this.$router.replace('/member')
          this.openInfoPopup()
        } else if (resp.status_code === 714) {
          this.setCreditTurn(resp.status_msg)
          // console.log(resp.status_msg)
          // this.$router.replace('unavailablewithdraw')
        }
      } catch (error) {}
      await this.setLoading(false)
    },
    imglink (short) {
      return get(this.imageBankMapping, [short])
    }
  },
  validations () {
    return {
      amount: {
        required
      }
    }
  }
}
</script>
<style>
</style>
